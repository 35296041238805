<template>
  <div>
    <NavBarGuest v-if="!isUserStaff"></NavBarGuest>
    <NavBar v-else></NavBar>
    <h1 class="text-h2 pt-6 text-center">Bienvenue sur l'espace Client</h1>
    <FooterBar></FooterBar>

  </div>
</template>

<script>
import NavBarGuest from "@/components/NavBarGuest.vue";
import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/Footer.vue";

export default {
  name: "GuestMain",
  components: {
    FooterBar,
    NavBarGuest,
    NavBar,
  },
  computed: {
    isUserStaff() {
      return localStorage.getItem("staff") === "true";
    },
  },
  mounted() {
    document.title = "Accueil";
  },
};
</script>

<style lang="scss"></style>
