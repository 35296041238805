<template>
  <div class="reset-password">
    <form @submit.prevent="resetPassword" class="mt-n8">
      <v-container fluid style="max-width: 1440px">
        <h1 class="text-h2 text-center mb-12">Réinitialiser le mot de passe</h1>
        <v-row
          class="justify-center flex-column align-center"
          style="transform: scale(0.85)"
        >
          <!-- EMAIL -->
          <v-col cols="5" class="accent px-7 rounded-lg elevation-1">
            <v-text-field
              v-model="email"
              type="email"
              label="Email"
              variant="underlined"
              required
              color="primary"
            >
            </v-text-field>
          </v-col>

          <p class="mt-2">
            Déjà membre ? <router-link to="/">Connexion</router-link>
          </p>
          <div v-if="error" class="error-message">{{ error }}</div>

          <!-- SUBMIT -->
          <v-col class="px-7 mt-2 d-flex justify-center">
            <v-btn
              type="submit"
              :disabled="!valid"
              color="primary"
              class="d-flex pa-7 px-11 primary rounded-lg"
              :loading="loading"
            >
              Réinitialiser le mot de passe
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </form>
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
    <FooterBar></FooterBar>

  </div>
</template>

<script>
import axios from "axios";
import { base_url, user_urls, header } from "@/API/config.js";
import FooterBar from "@/components/Footer.vue";

export default {
  name: "ResetPassword",
  components: {FooterBar},
  data: () => ({
    valid: true,
    email: "",
    error: "",
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "",
    loading: false,
  }),
  methods: {
    async resetPassword() {
      this.loading = true;

      // Suppression de tous les espaces dans l'email
      const cleanedEmail = this.email.replace(/\s+/g, "");

      const url = `${base_url}${user_urls.reset_password}`;
      try {
        await axios.post(url, { email: cleanedEmail }, header);
        this.snackbarMessage = `Un e-mail de réinitialisation du mot de passe a été envoyé à <strong>${cleanedEmail}</strong>`;
        this.snackbarColor = "success";
      } catch (error) {
        console.error(error);
        this.snackbarMessage = `Une erreur s'est produite lors de la réinitialisation du mot de passe pour <strong>${cleanedEmail}</strong>`;
        this.snackbarColor = "error";
      } finally {
        this.loading = false;
        this.snackbar = true;
      }
    },
  },
  mounted() {
    document.title = "Réinitialisation du mot de passe";
  },
};
</script>

<style lang="scss"></style>
