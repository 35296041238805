<template>
  <div class="details power-bi">
    <v-btn
      class="return-reports"
      onclick="history.back()"
      color="white"
      outlined
    >
      Exit
    </v-btn>
    <PowerBIReportEmbed
      v-if="!loading"
      :embedConfig="embedConfig"
      :class="{ reportClass: true }"
      :phasedEmbedding="false"
      :eventHandlers="eventHandlers"
    ></PowerBIReportEmbed>
    <div v-else class="loading-message text-center">
      Chargement du rapport PBI...
    </div>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import { PowerBIReportEmbed } from "powerbi-client-vue-js";
import { models } from "powerbi-client";
import axios from "axios";
import FooterBar from "@/components/Footer.vue";
import { base_url, user_urls, pbi_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "DashBoard",
  components: {
    PowerBIReportEmbed,
    FooterBar,
  },

  data() {
    return {
      embedConfig: {
        type: "",
        embedUrl: "",
        id: "",
        accessToken: "",
        tokenType: "",
        settings: {},
      },
      eventHandlers: new Map([
        ["loaded", () => console.log("Report loaded")],
        ["rendered", () => console.log("Report rendered")],
        ["error", (event) => console.log(event.detail)],
      ]),
      loading: true,
      error: null,
      reportId: process.env.VUE_APP_MANAGER_REPORT_ID,
      workspaceId: process.env.VUE_APP_WORKSPACE_ID,
      requestBody: {
        accessLevel: "View",
        identities: [
          {
            username: "",
            roles: ["RLS email"],
            datasets: [process.env.VUE_APP_MANAGER_DATASETS],
          },
        ],
      },
    };
  },

  methods: {
    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    async getPowerBIToken(userId) {
      try {
        // Get user email
        const userResponse = await this.getUserDetails(userId);
        const userEmail = userResponse.data.email;
        this.requestBody.identities[0].username = userEmail;

        // Payload
        const requestPayload = {
          workspaceId: this.workspaceId,
          reportId: this.reportId,
          request_body: this.requestBody,
        };

        // Get token
        const url = `${base_url}${pbi_urls.get_token}`;
        const response = await axios.post(url, requestPayload, header);
        const data = response.data;

        this.embedConfig = {
          type: "report",
          accessToken: data.embedToken,
          id: this.reportId,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${this.reportId}&groupId=${this.workspaceId}`,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        };
      } catch (error) {
        console.error("Error getting PowerBI Report :", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    document.title = "Rapports Power BI";
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("user") || this.$store.state.auth.id; // ID de l'utilisateur connecté
    this.getPowerBIToken(userId);
  },
};
</script>

<style lang="scss"></style>
