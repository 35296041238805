<template>
  <div class="signin">
    <form @submit.prevent="signin" class="mt-n8">
      <v-container fluid style="max-width: 1440px">
        <h1 class="text-h2 text-center mb-12">Se connecter</h1>
        <v-row
          class="justify-center flex-column align-center"
          style="transform: scale(0.85)"
        >
          <!-- USERNAME -->
          <v-col cols="5" class="accent px-7 rounded-lg mb-10 elevation-1">
            <v-text-field
              v-model="username"
              type="text"
              label="E-Mail"
              variant="underlined"
              required
              color="primary"
            ></v-text-field>
          </v-col>

          <!-- PASSWORD -->
          <v-col cols="5" class="accent px-7 rounded-lg elevation-1">
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              label="Mot de passe"
              hint="Minimum 8 caractères"
              @click:append="show1 = !show1"
              variant="underlined"
              required
              color="primary"
            ></v-text-field>
          </v-col>

          <p class="mt-2">
            Première connexion sur votre compte ?
            <router-link to="/init-password">Cliquez ici</router-link>
          </p>

          <p class="mt-1">
            Mot de passe oublié ?
            <router-link to="/reset-password">Cliquez ici</router-link>
          </p>

          <div v-if="error" class="error-message">{{ error }}</div>

          <!-- SUBMIT -->
          <v-col class="px-7 mt-2 d-flex justify-center">
            <v-btn
              type="submit"
              :disabled="!valid"
              color="primary"
              class="d-flex pa-7 px-11 primary rounded-lg"
              :loading="loading"
            >
              Se connecter
            </v-btn>
          </v-col>
        </v-row>
        <v-snackbar
          color="orange"
          v-model="showPasswordChangeSnackbar"
          :timeout="5000"
        >
          <p style="color: white">
            Il est temps de changer votre mot de passe !
          </p>
        </v-snackbar>
      </v-container>
    </form>
    <FooterBar></FooterBar>

  </div>
</template>

<script>
import axios from "axios";
import authExpirationMixin from "@/mixins/isLogged.js";
import { base_url, user_urls } from "@/API/config.js";
import FooterBar from "@/components/Footer.vue";

export default {
  mixins: [authExpirationMixin],
  components: {FooterBar},
  name: "SignIn",
  data: () => ({
    valid: true,
    username: "",
    password: "",
    error: "",
    show1: false,
    loading: false,
    showPasswordChangeSnackbar: false,
  }),

  methods: {
    async signin() {
      this.loading = true;
      const cleanedUsername = this.username.replace(/\s+/g, "");
      const cleanedPassword = this.password.replace(/\s+/g, "");

      if (!cleanedUsername || !cleanedPassword) {
        this.error = "Le nom d'utilisateur et le mot de passe sont requis.";
        this.loading = false;
        return;
      }

      try {
        await this.$store.dispatch("auth/signin", {
          username: cleanedUsername,
          password: cleanedPassword,
        });

        const details = await this.getUserDetails(this.$store.state.auth.id);

        const lastPasswordChange = new Date(details.last_password_change);
        const now = new Date();
        const diff = Math.abs(now - lastPasswordChange);
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

        if (diffDays > 90) {
          this.showPasswordChangeSnackbar = true;
          setTimeout(() => {
            this.redirectAfterLogin();
          }, 4000);
        } else {
          this.redirectAfterLogin();
        }
      } catch (error) {
        console.error("Error in signin process:", error);
        this.error = "Nom d'utilisateur ou mot de passe invalide.";
      } finally {
        if (!this.showPasswordChangeSnackbar) {
          this.loading = false;
        }
      }
    },

    getUserDetails(userId) {
      const token = this.$store.state.auth.token;
      if (!token) {
        console.error("Token not found in store");
        return Promise.reject("Authentication token is missing");
      }
      const url = `${base_url}${user_urls.get_details(userId)}`;
      return axios
        .get(url, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => response.data);
    },

    redirectAfterLogin() {
      this.$router.push({ name: "dashboard" }).then(() => {
        location.reload();
      });
    },
  },

  mounted() {
    document.title = "Connexion";
  },
};
</script>

<style lang="scss">
.checkbox .v-input__control + .v-input__details {
  display: none;
}

.signin,
.reset-password {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
