<template>
  <div class="details trainingbloc">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails du Parcours</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
            class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <!-- column 1 -->
              <v-list-item
                  class="w-100 pa-0"
                  v-for="key in keyOrder1"
                  :key="key"
              >
                <!-- if editable -->
                <div v-if="isEditable(key)">
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div v-if="key === 'TXT_CLCursusCodeLL'">
                        <div class="scrollable-container">
                          <v-chip
                              v-for="codeLL in codells"
                              :key="codeLL.ID_Ref_DRLGroups_CLCursusCodeLZ"
                              color="dark"
                              class="ma-2"
                              closed
                          >
                            CODE: {{ codeLL.TXT_CLTrainingCodeLL }} ||
                            DOMAINE: {{ codeLL.Domain.NUM_CLDomainName }}
                            <template #close>
                              <v-icon
                                  small
                                  @click.stop="deleteTrainingBlocCodeLL(codeLL)"
                              >
                                mdi-close
                              </v-icon>
                            </template>
                          </v-chip>
                          <span v-if="!codells.length">Aucun Code LL disponible</span>
                        </div>
                      </div>
                    </div>
                    <div
                        class="details-table-infos__edit"
                        @click="openUpdateDialog(key)"
                    >
                      <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                    </div>
                  </div>
                </div>
                <!-- if not editable -->
                <div v-else>
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div>
                        {{ trainingbloc[key] }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-50">
              <!-- column 2 -->
              <v-list-item
                  class="w-100 pa-0"
                  v-for="key in keyOrder2"
                  :key="key"
              >
                <!-- if editable -->
                <div v-if="isEditable(key)">
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div>
                        {{ trainingbloc[key] }} -
                        <template
                            v-if="trainingbloc.TXT_TargetType !== 'Aucun'"
                        >
                          {{
                            trainingbloc.NUM_TargetDurationMinutes &&
                            convertMinutesToHours(
                                trainingbloc.NUM_TargetDurationMinutes
                            )
                          }}
                          {{
                            trainingbloc.NUM_TargetScore &&
                            trainingbloc.NUM_TargetScore + " %"
                          }}
                        </template>
                      </div>
                    </div>
                    <div
                        class="details-table-infos__edit"
                        @click="openUpdateDialog(key)"
                    >
                      <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                    </div>
                  </div>
                </div>
                <!-- if not editable -->
                <div v-else>
                  <div class="details-table-infos px-10 py-3">
                    <div class="details-table-infos__cell">
                      <div>
                        <strong>{{ keyTextMap[key] }} :</strong>
                      </div>
                      <div>
                        {{ trainingbloc[key] }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item>
            </div>
          </v-list>

          <TraningBlocSubscriptionsTable />

          <div class="details-dates mt-6 px-10 d-flex pb-8">
            <div
                class="w-100 pa-0 details-dates__element"
                v-for="key in keyOrder3"
                :key="key"
            >
              <div class="element">
                <div>
                  <strong>{{ keyTextMap[key] }} :</strong>
                </div>
                <div>
                  {{
                    key === "DAT_LMSCreated" ||
                    key === "DAT_LMSLastEdited" ||
                    key === "DAT_NDAppDeletionDate"
                        ? formatDate(trainingbloc[key])
                        : trainingbloc[key]
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>

    <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        persistent
        max-width="800"
        min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>

        <div v-if="fieldToUpdate === 'TXT_CLCursusCodeLL'">
          <v-data-table-server
              v-model="selectedCodell"
              :headers="headersCodell"
              :items="flatAvailableCodeLL"
              item-value="ID_Ref_DRLGroups_CLCursusCodeLZ"
              show-select
              dense
              class="elevation-1"
              :items-length="totalItems"
              v-model:options="tableOptions"
              :items-per-page-options="[10, 20, 50]"
              :items-per-page="itemsPerPage"
              @update:options="onTableOptionsUpdate"
          >
            <template #item.TXT_CLTrainingCodeLL="{ item }">
              {{ item.TXT_CLTrainingCodeLL }}
            </template>
            <template #item.DomainName="{ item }">
              {{ item.DomainName }}
            </template>
            <template #item.ID_Ref_DRLGroups_CLCursusCodeLZ="{ item }">
              {{ item.ID_Ref_DRLGroups_CLCursusCodeLZ }}
            </template>
            <template #no-data>
              <v-alert type="info" elevation="2">
                Aucun résultat trouvé
              </v-alert>
            </template>
          </v-data-table-server>
        </div>


        <v-card-text v-if="fieldToUpdate === 'TXT_TargetType'">
          <v-select
              v-model="trainingbloc.TXT_TargetType"
              :items="targetTypes"
              label="Type d'objectif"
              item-title="text"
              item-value="value"
          ></v-select>
          <v-text-field
              v-model="trainingbloc.NUM_TargetScore"
              label="Score"
              type="number"
              :disabled="scoreDisabled"
          ></v-text-field>
          <v-text-field
              v-model="trainingbloc.NUM_TargetDurationMinutes"
              label="Temps en minutes"
              type="number"
              :disabled="timeDisabled"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="(dialog = false), getTrainingblocDetails()"
          >Annuler</v-btn
          >
          <v-btn
              v-if="fieldToUpdate === 'TXT_CLCursusCodeLL'"
              color="primary"
              text
              @click="confirmUpdate"
          >Confirmer</v-btn
          >
          <v-btn
              v-else-if="fieldToUpdate === 'TXT_TargetType'"
              color="primary"
              text
              @click="confirmUpdate"
          >Confirmer</v-btn
          >
          <v-btn v-else color="primary" text @click="confirmUpdate"
          >Confirmer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
    <FooterBar></FooterBar>

  </div>
</template>


<script>
import NavBar from "@/components/NavBar.vue";
import TraningBlocSubscriptionsTable from "@/components/TraningBlocSubscriptionsTable.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url, codelz_urls,
  header,
  trainingblocs_urls,
} from "@/API/config.js";
import FooterBar from "@/components/Footer.vue";

export default {
  mixins: [authExpirationMixin],
  name: "trainingblocSlug",
  components: {
    FooterBar,
    NavBar,
    TraningBlocSubscriptionsTable,
  },
  watch: {
    "trainingbloc.TXT_TargetType": "handleTargetTypeChange",
  },
  data() {
    return {
      showCodeLLInput: false,
      loading: false,
      keyTextMap: {
        TXT_TrainingBlocName: "Nom du parcours",
        TXT_TrainingBlocDescription: "Description",
        ID_D_TrainingBloc: "ID TrainingBloc",
        NUM_TargetDurationMinutes: "Objectif de temps en heures",
        NUM_TargetScore: "Objectif de score",
        TXT_NDContentCode: "Groupe Code",
        TXT_LMSTrainingBlocID: "ID Drillster",
        TXT_CLCursusCodeLL: "Codes LL",

        DAT_LMSCreated: "Création",
        // TXT_LastEditedBy: "Modifié par",
        DAT_LMSLastEdited: "Dernière modification",
        DAT_NDAppDeletionDate: "Date de suppression",
        TXT_TargetType: "Type de parcours",
      },
      keyOrder1: [
        "TXT_TrainingBlocName",
        "TXT_TrainingBlocDescription",
        "TXT_CLCursusCodeLL",
      ],
      keyOrder2: [
        "TXT_TargetType",
        "TXT_NDContentCode",
        "TXT_LMSTrainingBlocID",
      ],
      keyOrder3: [
        "DAT_LMSCreated",
        "DAT_LMSLastEdited",
        // , "TXT_LastEditedBy"
      ],
      keyOrder4: ["TXT_CLCursusCodeLL"],

      targetTypes: ["Score", "Temps", "Aucun"],
      selectedTargetType: [],
      trainingbloc: [],
      codells: [],
      availablecodell: [],
      selectedCodell: [],
      currentPage: 1,
      totalPages: 0,
      itemsPerPage: 10,
      totalItems: 0,
      tableOptions: { page: 1, itemsPerPage: 10 },
      headersCodell: [
        { text: "Code LL", value: "TXT_CLTrainingCodeLL" },
        { text: "Nom du Domaine", value: "Domain.NUM_CLDomainName" },
      ],
      originalTargetType: null,
      updatingTargetType: false,
      TXT_TargetType: null,
      originalNumTargetDurationMinutes: null,
      originalNumTargetScore: null,
      dialog: false,
      dialogTraineeAction: false,
      dialogTitle: "",
      selectAction: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },
  computed: {
    scoreDisabled() {
      return this.trainingbloc.TXT_TargetType !== "Score";
    },
    timeDisabled() {
      return this.trainingbloc.TXT_TargetType !== "Temps";
    },
    flatAvailableCodeLL() {
      return this.availablecodell.map((item) => ({
        ...item,
        DomainName: item.Domain?.NUM_CLDomainName || "N/A",
      }));
    },

  },
  methods: {
    getTrainingblocDetails() {
      const trainingblocId = this.$route.params.id;
      const url = `${base_url}${trainingblocs_urls.get_details(trainingblocId)}`
      axios
          .get(url, header)
          .then((response) => {
            this.trainingbloc = {
              ...response.data,
            };
            this.originalTargetType = this.trainingbloc.TXT_TargetType;
            this.traineesData = this.trainingbloc.Trainees;
          })
          .catch((error) => {
          console.error("Error fetching le parcours details", error);
        });
    },
    getTrainingblocCodeLL(){
      const url = `${base_url}${codelz_urls.getParcoursCodeLL}`
      const trainingBlocId = this.$route.params.id;
      const payload = {
        ID_D_TrainingBloc: trainingBlocId,
      }
      axios
          .post(url, payload, header)
          .then((response) => {
            this.codells = response.data.results || response.data
          })
          .catch((error) => {
            console.error("Error fetching le parcours CODELL", error);
          })
    },
    onTableOptionsUpdate(options) {
      this.tableOptions = options;
      const {page, itemsPerPage} = options;
      this.currentPage = page;
      this.currentPage = itemsPerPage;
      this.getDifTrainingblocCodeLL({ page, itemsPerPage });
    },
    getDifTrainingblocCodeLL(options) {
      const { page, itemsPerPage } = options;
      this.loading = true
      let url = `${base_url}${codelz_urls.getDifParcoursCodeLL}?page=${page}&page_size=${itemsPerPage}`;
      const trainingBlocId = this.$route.params.id;
      const payload = {
        ID_D_TrainingBloc: trainingBlocId,
      };

      axios
          .post(url, payload, header)
          .then((response) => {
            const resultsArray = response.data.results.results;
            this.totalItems = response.data.count;
            if (Array.isArray(resultsArray)) {
              this.availablecodell = resultsArray;
              this.totalPages = Math.ceil(response.data.count / this.itemsPerPage);
              this.currentPage = page;
            } else {
              console.error("Expected an array but got: ", resultsArray);
            }
          })
          .catch((error) => {
            console.error("Error fetching available CODE LL: ", error);
          });
    },
    deleteTrainingBlocCodeLL(codell){
      const payload = {
        ID_D_TrainingBloc: null,
        ID_Ref_DRLGroups_CLCursusCodeLZ: codell.ID_Ref_DRLGroups_CLCursusCodeLZ,
        TXT_CLTrainingCodeLL: codell.TXT_CLTrainingCodeLL,
        TXT_CLCursusCodeLZ: codell.TXT_CLCursusCodeLZ,
      }
      const url = `${base_url}${codelz_urls.update(codell.ID_Ref_DRLGroups_CLCursusCodeLZ)}`
      axios
          .put(url, payload, header)
          .then(() => {
            this.snackbarMessage = `Confirmation de la suppression du codell "${codell.TXT_CLTrainingCodeLL} au parcours ".`;
            this.snackbarColor = "success";
            this.snackbar = true;
            this.codells = this.codells.filter(
                (item) => item.ID_Ref_DRLGroups_CLCursusCodeLZ !== codell.ID_Ref_DRLGroups_CLCursusCodeLZ
            );
          })
          .catch((error) => {
            console.error("Error removing codell from parcours: ", error)
            this.snackbarMessage="";
            this.snackbarColor="error";
            this.snackbar = true;
          })
    },

    addTrainingBlocCodeLL(){
      if (!this.selectedCodell || this.selectedCodell.length === 0) {
        console.error("No codell  selected.");
        alert("Veuillez sélectionner un ou plusieurs code ll avant de continuer.");
        return;
      }
      const promises = this.selectedCodell.map((codeID) => {
        const codell = this.flatAvailableCodeLL.find(
            (item) => item.ID_Ref_DRLGroups_CLCursusCodeLZ === codeID
        );
        if (!codell) {
          console.error(`Could not find codell with ID: ${codeID}`);
          return Promise.reject(`Invalid ID: ${codeID}`);
        }
        const url = `${base_url}${codelz_urls.update(codell.ID_Ref_DRLGroups_CLCursusCodeLZ)}`
        const payload = {
          ID_D_TrainingBloc: this.$route.params.id,
          ID_Ref_DRLGroups_CLCursusCodeLZ: codell.ID_Ref_DRLGroups_CLCursusCodeLZ,
          TXT_CLTrainingCodeLL: codell.TXT_CLTrainingCodeLL,
          TXT_CLCursusCodeLZ: codell.TXT_CLCursusCodeLZ,
        };

        return axios.put(url, payload, header);
      });
      Promise.all(promises).then(() => {
        this.snackbarMessage = `Les code ll ont été ajoutés au parcours avec succès.`;
        this.snackbarColor = "success";
        this.snackbar = true;
        this.getTrainingblocCodeLL();
        this.updateTrainingbloc()
      }).catch((error) => {
        console.error("Error adding code ll to parcours: ", error);
        this.snackbarMessage = `Une erreur s'est produite lors de l'ajout des code ll au parcours.`;
        this.snackbarColor = "error";
        this.snackbar = true;
      });
    },
    openUpdateDialog(key) {
      if (this.isEditable(key)) {
        this.fieldToUpdate = key;
        this.newFieldValue = this.trainingbloc[key];

        if (key == "TXT_CLCursusCodeLL" || key == "TXT_TargetType") {
          this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
          this.dialog = true;
        } else {
          this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
          this.dialog = true;
        }
      }
    },

    confirmUpdate() {
      if (this.fieldToUpdate === "TXT_TargetType") {
        this.updateTrainingbloc(
          this.fieldToUpdate,
          this.trainingbloc.TXT_TargetType
        );
      } else if (this.fieldToUpdate === "TXT_CLCursusCodeLL") {
        this.addTrainingBlocCodeLL()
      }
      else if (this.newFieldValue !== null) {
        this.updateTrainingbloc(this.fieldToUpdate, this.newFieldValue);
      }
      this.dialog = false;
      this.getTrainingblocDetails();
    },

    updateTrainingbloc(field, newValue) {
      const trainingblocId = this.$route.params.id;
      const url = `${base_url}${trainingblocs_urls.update(trainingblocId)}`;

      const updatedData = {
        NUM_TargetDurationMinutes: this.trainingbloc.NUM_TargetDurationMinutes,
        NUM_TargetScore: this.trainingbloc.NUM_TargetScore,
        TXT_TrainingBlocName: this.trainingbloc.TXT_TrainingBlocName,
        TXT_TargetType: this.trainingbloc.TXT_TargetType || "Aucun", // Ensure not null
        DAT_LMSCreated: this.trainingbloc.DAT_LMSCreated,
        [field]: newValue, // Override the field to update with the new value
        DAT_LMSLastEdited: new Date().toISOString(), // Current date
        TXT_LastEditedBy: this.$store.state.auth.id, // Current user ID from Vuex store
      };

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.trainingbloc = response.data;
          this.getTrainingblocDetails();

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    formatDate(date) {
      if (!date) return "N/A";
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    isEditable(key) {
      const editableKeys = [
        "TXT_CLCursusCodeLL",
        "NUM_TargetDurationMinutes",
        "NUM_TargetScore",
        "TXT_TargetType",
      ];
      return editableKeys.includes(key);
    },

    handleTargetTypeChange() {
      const newVal = this.trainingbloc.TXT_TargetType;

      if (newVal !== this.originalTargetType) {
        if (newVal === "Aucun") {
          this.trainingbloc.NUM_TargetDurationMinutes = null;
          this.trainingbloc.NUM_TargetScore = null;
        } else if (newVal === "Temps") {
          this.trainingbloc.NUM_TargetScore = null;
        } else if (newVal === "Score") {
          this.trainingbloc.NUM_TargetDurationMinutes = null;
        }
      } else if (newVal === this.originalTargetType) {
        this.getTrainingblocDetails();
      }
    },

    convertMinutesToHours(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} heures ${
        remainingMinutes > 0 ? remainingMinutes + " minutes" : ""
      }`;
    },
  },
  created() {
    console.log("Starting created hook");
    try {
      this.getTrainingblocDetails();
    } catch (error) {
      console.error("Error in created hook:", error);
    }
  },
  mounted() {
    this.getTrainingblocCodeLL();
    this.getDifTrainingblocCodeLL(this.tableOptions);
  }
};
</script>

<style lang="scss">
.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
.CodesLZ {
  margin-top: 10px;
}

.scrollable-container {
  max-height: 150px; /* Adjust the height as needed */
  overflow-y: auto;
  border: 1px solid #ddd; /* Optional border for better visibility */
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
}
</style>
