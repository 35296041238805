<template>
  <v-footer class="footer">
    <v-row justify="space-between" no-gutters>
      <v-col class="text-center mt-4" cols="4">
        <strong class="footer-text">NOUVELLES DONNES</strong>
      </v-col>
      <v-col class="text-right mt-4" cols="4">
        <v-btn @click="dialog = true">Politique de Sécurité</v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline">Politique de Sécurité des Systèmes d'Information</v-card-title>
        <v-card-text>
          <div class="pssi-content">
            <h2>Politique de Sécurité des Systèmes d'Information (PSSI)</h2>
            <p>Ndataviz est une application développée par Nouvelles Donnes qui permet de suivre l'avancement des apprenants sur les formations digitales proposées par l'entreprise. À travers cette application, Nouvelles Donnes met à disposition des clients deux types de rapports:</p>
            <ul>
              <li>Un rapport à destination des apprenants avec leur avancement individuel.</li>
              <li>Un rapport à destination des managers ou services de formation leur permettant de visualiser les avancements individuels des apprenants qui leur ont été affectés ainsi que des données agrégées par parcours de formation.</li>
            </ul>
            <p>La présente Politique de Sécurité des Systèmes d'Information (PSSI) vise à protéger les informations et les actifs numériques de l'organisation, en particulier les données personnelles collectées par le logiciel développé par l'organisation, ndataviz. Ce logiciel enregistre des données personnelles (email, nom, prénom, date de naissance, lieu de travail) dans une base SQL Azure managée. Toutes les données relatives à ce logiciel sont stockées en France. Cette politique établit les principes, procédures et responsabilités pour garantir la confidentialité, l'intégrité et la disponibilité de ces données.</p>
            <h3>2. Champ d'application</h3>
            <p>Cette politique s'applique à tous les employés, prestataires, partenaires et tiers ayant accès aux systèmes d'information de l'organisation. Elle couvre spécifiquement le logiciel collectant des données personnelles et la base SQL Azure managée qui stocke ces données. L'email étant le seul champ obligatoire, la politique veille à limiter la collecte de données superflues et à respecter les principes de minimisation des données.</p>
            <h3>3. Principes Directeurs</h3>
            <ul>
              <li><strong>Confidentialité:</strong> Les informations sensibles sont accessibles uniquement aux personnes autorisées, avec une attention particulière aux données personnelles. Toute divulgation non autorisée est strictement interdite.</li>
              <li><strong>Intégrité:</strong> Les données sont exactes, complètes et protégées contre toute altération non autorisée. Cela inclut des contrôles rigoureux sur les modifications apportées aux systèmes d'information et aux bases de données.</li>
              <li><strong>Disponibilité:</strong> Les systèmes et informations critiques, y compris la base SQL Azure, sont accessibles en permanence pour répondre aux besoins opérationnels. Des solutions de sauvegarde et de reprise après sinistre ont été mises en place pour réduire les interruptions.</li>
              <li><strong>Conformité:</strong> Toutes les activités respectent les réglementations applicables, notamment le RGPD pour la protection des données personnelles, ainsi que toute autre norme sectorielle ou contractuelle en vigueur.</li>
              <li><strong>Transparence:</strong> L'organisation s'engage à informer les parties prenantes, y compris les employés, clients et apprenants, sur la manière dont leurs données sont collectées, stockées, utilisées et protégées. Cette transparence s'applique aussi aux processus d'évaluation et d'utilisation des données de progression.</li>
              <li><strong>Minimisation des Données:</strong> Seules les données strictement nécessaires aux activités opérationnelles sont collectées et conservées. Les données superflues sont évitées et les pratiques d'anonymisation ou de pseudonymisation sont privilégiées lorsque possible.</li>
              <li><strong>Éthique:</strong> L'utilisation des données, notamment les données de progression des apprenants, est guidée par des principes d'équité et de respect des droits des individus. Aucune décision automatique ou défavorisant injustement un individu ne sera prise sans validation humaine.</li>
            </ul>
            <p>Ces principes servent de base à toutes les mesures et procédures de sécurité mises en place pour protéger les actifs numériques de l'organisation.</p>
            <h3>4. Responsabilités</h3>
            <ul>
              <li><strong>Direction:</strong> Définit et valide la politique, et s'assure de la conformité réglementaire.</li>
              <li><strong>DPO (Délégué à la Protection des Données):</strong> Supervise la conformité aux règles relatives à la protection des données personnelles, conseille sur les obligations légales et agit comme point de contact pour les autorités compétentes et les individus concernés.</li>
              <li><strong>Département Informatique:</strong> Met en œuvre et supervise les mesures de sécurité, en particulier pour la base SQL Azure.</li>
              <li><strong>Employés:</strong> Respectent les règles et signalent tout incident de sécurité impliquant des données personnelles.</li>
              <li><strong>Gestionnaires de projets spécifiques:</strong> S'assurent que les données personnelles des clients et les données de progression sauvegardées sur SQL Azure respectent les règles de sécurité et les exigences spécifiques des clients en matière de rétention des données.</li>
              <li><strong>Clients:</strong>
                <ul>
                  <li>Collecte initiale: Les clients sont responsables de la collecte et de la validation des données personnelles qu'ils fournissent à l'organisation. Ils doivent s'assurer que ces données sont exactes, pertinentes et limitées aux finalités strictement nécessaires.</li>
                  <li>Information des apprenants: Les clients doivent informer les apprenants des modalités de collecte et d'utilisation de leurs données personnelles, en conformité avec les réglementations applicables (par exemple, le RGPD).</li>
                  <li>Transmission sécurisée: Lors de la transmission des données à l'organisation, les clients doivent utiliser des canaux sécurisés pour prévenir tout accès non autorisé.</li>
                  <li>Gestion des accès: Les clients doivent spécifier et maintenir à jour la liste des managers ou services autorisés à accéder aux données des apprenants, en informant rapidement l'organisation de tout changement.</li>
                  <li>Obligation de suivi: Les clients doivent coopérer avec l'organisation pour résoudre tout problème lié aux données personnelles, y compris les demandes de rectification ou de suppression formulées par les apprenants.</li>
                </ul>
              </li>
            </ul>
            <h3>5. Mesures de Sécurité</h3>
            <h4>5.1. Contrôle des Accès</h4>
            <ul>
              <li>Mise en place d'un système d'authentification forte (MFA) pour accéder à la base SQL Azure.</li>
              <li>Gestion des droits d'accès selon le principe du moindre privilège.</li>
              <li>Restriction des accès administratifs aux seuls personnels autorisés.</li>
              <li>Politique de gestion des mots de passe:
                <ul>
                  <li>Utilisation de mots de passe complexes (minimum 8 caractères, comprenant majuscules, minuscules, chiffres et caractères spéciaux).</li>
                  <li>Rotation régulière des mots de passe (tous les 90 jours).</li>
                  <li>Interdiction de réutiliser les anciens mots de passe.</li>
                  <li>Stockage des mots de passe de manière sécurisée (hachage et salage).</li>
                  <li>Sensibilisation des employés sur les pratiques sécuritaires, comme l'utilisation d'un gestionnaire de mots de passe.</li>
                  <li>Interdiction d'utiliser le nom ou prénom.</li>
                </ul>
              </li>
            </ul>
            <h4>5.2. Sécurité des Données</h4>
            <ul>
              <li>Configuration des alertes automatiques:
                <ul>
                  <li>Activer les alertes dans Microsoft Defender for SQL pour signaler les activités suspectes, comme les tentatives d'accès non autorisées ou les requêtes inhabituelles.</li>
                  <li>Configurer des notifications par email ou via Azure Monitor pour informer rapidement les administrateurs des incidents détectés.</li>
                </ul>
              </li>
              <li>Surveillance continue:
                <ul>
                  <li>Activer les journaux d'audit pour enregistrer toutes les activités sur la base SQL Azure, y compris les modifications de données et les connexions des utilisateurs.</li>
                  <li>Analyser régulièrement les journaux pour détecter des schémas ou des anomalies.</li>
                </ul>
              </li>
              <li>Gestion des privilèges:
                <ul>
                  <li>Examiner régulièrement les droits d'accès des utilisateurs et supprimer ceux qui ne sont plus nécessaires.</li>
                  <li>Appliquer le principe du moindre privilège pour limiter les risques liés à des comptes disposant de droits excessifs.</li>
                </ul>
              </li>
            </ul>
            <h4>5.3. Utilisation des Données de Progression</h4>
            <p>Les données de progression des apprenants sont utilisées pour évaluer leurs performances et leur conformité avec les exigences professionnelles. Ces données peuvent avoir un impact direct sur leurs qualifications professionnelles, voire sur leur maintien en poste. Toutes les évaluations basées sur ces données doivent être réalisées dans le respect des principes d'équité et de transparence.</p>
            <h4>5.4. Protection contre les Menaces</h4>
            <ul>
              <li>Surveillance continue de la base SQL Azure avec des outils de détection des intrusions.</li>
              <li>Installation de solutions de sécurité pour protéger les serveurs applicatifs et les réseaux associés.</li>
              <li>Mise à jour régulière des systèmes et applications pour corriger les vulnérabilités.</li>
            </ul>
            <h4>5.5. Gestion des Incidents</h4>
            <ul>
              <li>Détection et signalement: Tout employé ou client qui identifie un incident de sécurité impliquant des données personnelles doit immédiatement le signaler au DPO ou au service client.
                <ul>
                  <li>DPO: benoit.fevrier@nouvellesdonnes.com</li>
                  <li>Service client: service.client@nouvellesdonnes.com</li>
                </ul>
              </li>
              <li>Analyse initiale: Le DPO ou le responsable désigné procède à une évaluation rapide pour déterminer l'ampleur de l'incident et identifier les données concernées.</li>
              <li>Confinement: Des mesures sont prises pour limiter l'impact de l'incident (par exemple, désactivation des accès compromis, isolation des systèmes affectés).</li>
              <li>Communication avec les clients: Les clients impactés sont informés par le service client via des canaux appropriés (email ou autre moyen sécurisé), expliquant la nature de l'incident, les actions prises et les étapes suivantes.</li>
              <li>Notification des autorités: En cas de violation majeure, le DPO notifiera les autorités compétentes, comme la CNIL, dans un délai de 72 heures conformément au RGPD.</li>
              <li>Plan de remédiation: Une fois l'incident contenu, un plan d'action correctif est élaboré pour renforcer les mesures de sécurité et éviter que l'incident ne se reproduise.</li>
              <li>Documentation: Chaque incident est consigné dans un registre détaillant les causes, impacts, actions prises et leçons apprises pour améliorer les processus.</li>
            </ul>
            <h4>5.6. Sensibilisation et Formation</h4>
            <ul>
              <li>Sessions régulières de sensibilisation sur la protection des données personnelles et la cybersécurité.</li>
              <li>Formation obligatoire pour tous les employés utilisant ou ayant accès au logiciel et à la base SQL Azure.</li>
            </ul>
            <h3>6. Audit et Contrôle</h3>
            <ul>
              <li>Réalisation d'audits réguliers de la base SQL Azure pour évaluer la conformité aux politiques et réglementations.</li>
              <li>Mise en place d'indicateurs de performance pour suivre l'efficacité des mesures de sécurité (par exemple, nombre d'incidents, temps moyen de résolution).</li>
              <li>Utilisation de standards reconnus, comme l'ISO 27001, pour guider les audits et contrôles.</li>
            </ul>
            <h3>7. Sanctions</h3>
            <ul>
              <li>Tout manquement à cette politique, en particulier en ce qui concerne la protection des données personnelles, peut entraîner des mesures disciplinaires, pouvant aller jusqu'à la révocation ou la poursuite judiciaire.</li>
              <li>Les partenaires ou prestataires externes non conformes peuvent se voir résilier leurs contrats.</li>
              <li>Les incidents graves seront rapportés à la direction pour une prise de décision adaptée.</li>
            </ul>
            <p><strong>Direction Générale</strong><br>Jean-Baptiste Hueber<br>Le 24/02/2025</p>
            <p><strong>DPO</strong><br>Benoit Février<br>Le 24/02/2025</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss">
.footer {
  height: 60px; /* Set a fixed height */
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer-text {
  font-size: 0.8rem;
}

.pssi-content {
  padding: 16px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.pssi-content h2, .pssi-content h3, .pssi-content h4 {
  margin-top: 16px;
}

.pssi-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.pssi-content ul ul {
  list-style-type: circle;
  margin-left: 20px;
}

.pssi-content p {
  margin: 8px 0;
}
</style>