<template>
  <v-card class="data-table">
    <v-card-title>
      <v-text-field
        class="min-100"
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        label="Rechercher..."
        single-line
        color="primary"
        @keyup.enter="getTrainingCourses(true)"
        @click:append="getTrainingCourses(true)"
        @click:clear="resetSearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table-server
      v-model:page="currentPage"
      :show-current-page="true"
      items-per-page-text="Nombre d'élements par page."
      :headers="headers"
      :page="currentPage"
      :items-per-page-options="[10, 20, 50, 100]"
      :items-length="totalTrainingCourses"
      :items="trainingcourses"
      no-data-text="Aucune donnée"
      :loading="loading"
      loading-text="Loading... Please wait"
      @update:options="options = $event"
    >
      <template v-slot:[`item.TXT_Liaison`]="{ item }">
        <template v-if="item.TXT_SubTheme">
          <div class="linked-relation">
            <p>
              {{ item.TXT_SubTheme }}
            </p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.TXT_Liaison_2`]="{ item }">
        <template v-if="item.TXT_Theme">
          <div class="linked-relation">
            <p>
              {{ item.TXT_Theme }}
            </p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.Édition`]="{ item }">
        <v-icon
          icon="mdi-pencil"
          size="x-large"
          @click="openDialog(item)"
        ></v-icon>
      </template>

      <template v-slot:[`item.alert`]="{ item }">
        <div class="data-table-warning" v-if="!item.ID_D_SubTheme">
          <v-icon class="warning_theme_subthemes">mdi-comment-alert</v-icon>
          <v-tooltip activator="parent" location="left">
            Merci de sélectionner un Sous-Thème
          </v-tooltip>
        </div>
        <div v-else>
          <v-icon class="warning_theme_subthemes_linked"
            >mdi-comment-check</v-icon
          >
        </div>
      </template>
    </v-data-table-server>

    <v-dialog
      v-if="itemToEdit"
      v-model="dialog[itemToEdit.ID_D_Training]"
      transition="dialog-top-transition"
      persistent
      min-width="800"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Modifier le sous-thème </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="subThemeSearch"
            clearable
            prepend-inner-icon="mdi-magnify"
            label="Rechercher un sous-thème..."
            single-line
            hide-details
            @keyup.enter="getSubThemes(true)"
            @click:prepend-inner="getSubThemes(true)"
            @click:clear="resetSubThemeSearch"
            density="compact"
            bg-color="grey-darken-3"
            color="primary"
          ></v-text-field>
          <v-data-table-server
            :headers="subThemeHeaders"
            :items="subThemes"
            :items-length="totalSubThemes"
            v-model:page="subThemeCurrentPage"
            :items-per-page="subThemeItemsPerPage"
            :loading="subThemeLoading"
            @update:options="updateSubThemeOptions"
            density="compact"
          >
            <template v-slot:[`item.selected`]="{ item }">
              <v-checkbox
                class="d-flex justify-center ma-0 pa-0"
                v-model="selectedSubTheme"
                :value="item.ID_D_SubTheme"
                @change="updateSelectedSubTheme(item)"
                density="compact"
              ></v-checkbox>
            </template>
          </v-data-table-server>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialog[itemToEdit.ID_D_Training] = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" @click="updateItem">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </v-card>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  trainingcourses_urls,
  subthemes_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ContenuFirstPanelItem",
  components: {},
  props: {},
  data() {
    return {
      search: "",
      subThemeSearch: "",
      sortBy: [],
      subThemes: [],
      trainingcourses: [],
      totalTrainingCourses: 0,
      totalSubThemes: 0,
      loading: false,
      subThemeLoading: false,
      itemsPerPage: 10,
      subThemeItemsPerPage: 10,
      currentPage: 1,
      subThemeCurrentPage: 1,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      subThemeOptions: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      itemToEdit: null,
      dialogJustOpened: false,
      trainingcourseId: null,

      isLoading: "",

      selectedSubTheme: null,
      dialog: {},
      headers: [
        {
          title: "Code Contenu",
          value: "TXT_L2_NDContentCode",
          key: "codeContenu",
          sortable: false,
        },
        {
          title: "Drillster",
          value: "TXT_L2_LMSTitle",
          key: "Drillster",
          sortable: false,
        },
        {
          title: "Thème",
          value: "TXT_Liaison_2",
          key: "TXT_Liaison_2",
          sortable: false,
        },
        {
          title: "Sous-Thème",
          value: "TXT_Liaison",
          key: "TXT_Liaison",
          sortable: false,
        },
        {
          title: "Édition",
          value: "Édition",
          key: "Édition",
          sortable: false,
        },
        {
          title: "Attention",
          value: "alert",
          key: "alert",
          sortable: false,
        },
      ],
      subThemeHeaders: [
        { title: "Sélectionner", key: "selected", sortable: false, width: 100 },
        { title: "Thème", key: "ThemeName", sortable: false, width: 500 },
        {
          title: "Sous-Thème",
          key: "TXT_SubTheme",
          sortable: false,
        },
      ],
    };
  },

  computed: {},
  watch: {
    options: {
      handler() {
        this.getTrainingCourses();
      },
      deep: true,
    },
    subThemeOptions: {
      handler() {
        this.getSubThemes();
      },
      deep: true,
    },
  },
  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${trainingcourses_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getTrainingCourses(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;

      let url = `${base_url}${trainingcourses_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalTrainingCourses = response.data.count;
        this.trainingcourses = response.data.results.results.map(
          (trainingcourse) => ({
            ...trainingcourse,
            DAT_Created: trainingcourse.DAT_Created
              ? this.formatDate(trainingcourse.DAT_Created)
              : "N/A",
            DAT_LastEdited: trainingcourse.DAT_LastEdited
              ? this.formatDate(trainingcourse.DAT_LastEdited)
              : "N/A",
          })
        );
      } catch (error) {
        console.error("Error fetching training courses:", error);
      } finally {
        this.loading = false;
      }

      await this.checkForUpdates();
    },

    async getSubThemes(resetPage = false) {
      if (resetPage) {
        this.subThemeCurrentPage = 1;
        this.subThemeOptions.page = 1;
      }

      this.subThemeLoading = true;
      const cacheVersion = await this.initializeCacheBusting();
      let url = `${base_url}${subthemes_urls.get_all}?page=${this.subThemeCurrentPage}&page_size=${this.subThemeItemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.subThemeSearch) {
        url += `&query=${encodeURIComponent(this.subThemeSearch)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalSubThemes = response.data.count;
        this.subThemes = response.data.results.results.map((subtheme) => ({
          ...subtheme,
          ThemeName: this.getThemeName(subtheme.Themes),
        }));
      } catch (error) {
        console.error("Error fetching sub-themes:", error);
      } finally {
        this.subThemeLoading = false;
      }

      this.checkForUpdates();
    },

    getThemeName(themes) {
      if (themes === null) {
        return "";
      } else if (typeof themes === "string") {
        return themes;
      } else if (Array.isArray(themes) && themes.length > 0) {
        return themes[0].TXT_Theme || "";
      } else {
        return "";
      }
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        trainingcourses_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getTrainingCourses();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getTrainingCourses(true);
    },

    resetSubThemeSearch() {
      this.subThemeSearch = "";
      this.getSubThemes(true);
    },

    openDialog(item) {
      this.dialogJustOpened = true;

      if (item && item.ID_D_Training !== undefined) {
        this.itemToEdit = item;
        this.trainingcourseId = item.ID_D_Training;

        this.dialog[this.itemToEdit.ID_D_Training] = true;
        this.selectedSubTheme = item.ID_D_SubTheme;
        this.getSubThemes();
      } else {
        console.error("item or item.ID_D_Training is undefined", item);
      }
    },

    updateSubThemeOptions(newOptions) {
      this.subThemeCurrentPage = newOptions.page;
      this.subThemeItemsPerPage = newOptions.itemsPerPage;
      this.getSubThemes();
    },

    updateSelectedSubTheme(item) {
      this.selectedSubTheme = item.ID_D_SubTheme;
    },

    async updateItem() {
      const subthemeId = this.selectedSubTheme;

      const trainingCourseId = this.trainingcourseId;
      const putUrl = `${base_url}${trainingcourses_urls.update(
        trainingCourseId
      )}`;

      const dataToUpdate = {
        ID_D_SubTheme: subthemeId,
      };

      try {
        await axios.put(putUrl, dataToUpdate, header);
        this.dialog[this.itemToEdit.ID_D_Training] = false;
        const newCacheVersion = new Date().getTime().toString();
        this.updateCacheBusting(newCacheVersion);
        this.snackbar = true;

        this.snackbarMessage = `Une nouvelle <strong>Liaison</strong> a été ajoutée avec succès`;
        this.snackbarColor = "success";

        await this.getTrainingCourses();
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        this.snackbarMessage = `Une erreur s'est produite lors de la liaison <strong>Sous-Thème</strong>`;
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },
  },

  created() {
    this.getTrainingCourses();
  },
};
</script>

<style lang="scss"></style>
