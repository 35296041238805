<template>
  <v-card class="data-table">
    <v-card-title>
      <v-text-field
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        label="Rechercher..."
        single-line
        class="min-100"
        @input="filterTestDrills"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filteredTestDrills"
      :search="search"
      :loading="loading"
      :items-per-page="10"
      :items-per-page-options="[10, 20, 50, 100]"

      :footer-props="{
        'items-per-page-text': 'Éléments par page',
      }"
    >
      <template v-slot:[`item.TXT_Liaison`]="{ item }">
        <template v-if="item.TXT_Theme">
          <div class="linked-relation">
            <p>{{ item.TXT_Theme }}</p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.TXT_Liaison_2`]="{ item }">
        <template v-if="item.TXT_SubTheme">
          <div class="linked-relation">
            <p>{{ item.TXT_SubTheme }}</p>
          </div>
        </template>
      </template>

      <template v-slot:[`item.Édition`]="{ item }">
        <v-icon
          icon="mdi-pencil"
          size="x-large"
          @click="openDialog(item)"
        ></v-icon>
      </template>

      <template v-slot:[`item.alert`]="{ item }">
        <div class="data-table-warning" v-if="!item.ID_D_SubTheme">
          <v-icon class="warning_theme_subthemes">mdi-comment-alert</v-icon>
          <v-tooltip activator="parent" location="left">
            Merci de sélectionner un Sous-Thème
          </v-tooltip>
        </div>
        <div v-else>
          <v-icon class="warning_theme_subthemes_linked"
            >mdi-comment-check</v-icon
          >
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-if="itemToEdit"
      v-model="dialog[itemToEdit.ID_D_Training]"
      transition="dialog-top-transition"
      persistent
      min-width="800"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Modifier le sous-thème</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="subThemeSearch"
            clearable
            prepend-inner-icon="mdi-magnify"
            label="Rechercher un sous-thème..."
            single-line
            hide-details
            @keyup.enter="getSubThemes(true)"
            @click:prepend-inner="getSubThemes(true)"
            @click:clear="resetSubThemeSearch"
            density="compact"
            bg-color="grey-darken-3"
            color="primary"
          ></v-text-field>
          <v-data-table-server
            :headers="subThemeHeaders"
            :items="subThemes"
            :items-length="totalSubThemes"
            v-model:page="subThemeCurrentPage"
            :items-per-page="subThemeItemsPerPage"
            :loading="subThemeLoading"
            @update:options="updateSubThemeOptions"
            density="compact"
          >
            <template v-slot:[`item.selected`]="{ item }">
              <v-checkbox
                class="d-flex justify-center ma-0 pa-0"
                v-model="selectedSubTheme"
                :value="item.ID_D_SubTheme"
                @change="updateSelectedSubTheme(item)"
                density="compact"
              ></v-checkbox>
            </template>
          </v-data-table-server>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="dialog[itemToEdit.ID_D_Training] = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" @click="updateItem">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </v-card>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  testdrills_urls,
  subthemes_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ContenuThirdPanelItem",
  data() {
    return {
      search: "",
      subThemeSearch: "",
      testdrills: [],
      filteredTestDrills: [],
      subThemes: [],
      totalSubThemes: 0,
      loading: false,
      subThemeLoading: false,
      subThemeOptions: {
        page: 1,
        itemsPerPage: 5,
      },
      subThemeItemsPerPage: 10,
      subThemeCurrentPage: 1,
      cacheVersion: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      itemToEdit: null,
      testdrillId: null,

      selectedSubTheme: null,
      dialog: {},
      headers: [
        { title: "Drill", value: "TXT_L3_LMSTitle", key: "TXT_L3_LMSTitle" },
        { title: "Thème", value: "TXT_Liaison", key: "TXT_Liaison" },
        {
          title: "Sous-Thème",
          value: "TXT_Liaison_2",
          key: "TXT_Liaison_2",
        },
        { title: "Édition", value: "Édition", key: "Édition" },
        { title: "Attention", value: "alert", key: "alert" },
      ],
      subThemeHeaders: [
        {
          title: "Sélectionner",
          key: "selected",
          sortable: false,
          width: 100,
        },
        { title: "Thème", key: "ThemeName", sortable: false, width: 500 },
        {
          title: "Sous-thème",
          key: "TXT_SubTheme",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        await axios.get(`${base_url}${testdrills_urls.get_all_fake}`, header);
        cacheVersion = new Date().getTime().toString();
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getTestDrills() {
      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      let url = `${base_url}${testdrills_urls.get_all_fake}?cacheVersion=${cacheVersion}`;

      try {
        const response = await axios.get(url, header);
        this.testdrills = response.data;
        this.filteredTestDrills = [...this.testdrills];
      } catch (error) {
        console.error("Error fetching testdrills:", error);
      } finally {
        this.loading = false;
      }
    },

    filterTestDrills() {
      if (!this.search) {
        this.filteredTestDrills = [...this.testdrills];
      } else {
        const searchLower = this.search.toLowerCase();
        this.filteredTestDrills = this.testdrills.filter((item) =>
          Object.values(item).some(
            (val) => val && val.toString().toLowerCase().includes(searchLower)
          )
        );
      }
    },

    async getSubThemes(resetPage = false) {
      if (resetPage) {
        this.subThemeCurrentPage = 1;
        this.subThemeOptions.page = 1;
      }

      this.subThemeLoading = true;
      const cacheVersion = await this.initializeCacheBusting();
      let url = `${base_url}${subthemes_urls.get_all}?page=${this.subThemeCurrentPage}&page_size=${this.subThemeItemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.subThemeSearch) {
        url += `&query=${encodeURIComponent(this.subThemeSearch)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalSubThemes = response.data.count;
        this.subThemes = response.data.results.results.map((subtheme) => ({
          ...subtheme,
          ThemeName: this.getThemeName(subtheme.Themes),
        }));
      } catch (error) {
        console.error("Error fetching sub-themes:", error);
      } finally {
        this.subThemeLoading = false;
      }

      // this.checkForUpdates();
    },

    getThemeName(themes) {
      if (themes === null) {
        return "";
      } else if (typeof themes === "string") {
        return themes;
      } else if (Array.isArray(themes) && themes.length > 0) {
        return themes[0].TXT_Theme || "";
      } else {
        return "";
      }
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        testdrills_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getTestDrills();
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getTestDrills(true);
    },

    resetSubThemeSearch() {
      this.subThemeSearch = "";
      this.getSubThemes(true);
    },

    openDialog(item) {
      if (item && item.ID_D_Training !== undefined) {
        this.itemToEdit = item;
        this.testdrillId = item.ID_D_Training;
        this.dialog[this.itemToEdit.ID_D_Training] = true;
        this.selectedSubTheme = item.ID_D_SubTheme;
        this.getSubThemes();
      } else {
        console.error("item or item.ID_D_Training is undefined", item);
      }
    },

    updateOptions(newOptions) {
      this.options = newOptions;
      this.getTestDrills();
    },

    updateSubThemeOptions(newOptions) {
      this.subThemeCurrentPage = newOptions.page;
      this.subThemeItemsPerPage = newOptions.itemsPerPage;
      this.getSubThemes();
    },

    updateSelectedSubTheme(item) {
      this.selectedSubTheme = item.ID_D_SubTheme;
    },

    async updateItem() {
      const subthemeId = this.selectedSubTheme;
      const testdrillId = this.testdrillId;
      const putUrl = `${base_url}${testdrills_urls.update(testdrillId)}`;

      const dataToUpdate = {
        ID_D_SubTheme: subthemeId,
      };

      try {
        await axios.put(putUrl, dataToUpdate, header);
        this.dialog[this.itemToEdit.ID_D_Training] = false;

        const newCacheVersion = new Date().getTime().toString();
        this.updateCacheBusting(newCacheVersion);

        this.snackbar = true;
        this.snackbarMessage = `Une nouvelle <strong>Liaison</strong> a été ajoutée avec succès`;
        this.snackbarColor = "success";

        await this.getTestDrills();
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        this.snackbarMessage = `Une erreur s'est produite lors de la liaison <strong>Sous-Thème</strong>`;
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },
  },
  created() {
    this.getTestDrills();
  },
};
</script>
