<template>
  <div class="contenu">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Référentiel de Contenus</h1>

    <div class="mx-16 pb-5">
      <v-tabs
        fixed-tabs
        color="pink"
        class="tabs-drill mb-6 pl-16"
        v-model="activeTab"
      >
        <v-tab v-for="(item, index) in tabs" :key="index" :value="index">
          {{ item.label }}
        </v-tab>

        <div class="d-flex justify-end align-center">
          <NotificationBell :alerts="alerts" @change-tab="setActiveTab" />
        </div>
      </v-tabs>

      <v-window v-model="activeTab">
        <v-window-item :value="0" class="panel_1" ref="panel_1">
          <ContenuFirstPanelItem :activeTab="activeTab" :tabs="tabs" />
        </v-window-item>
        <v-window-item :value="1" class="panel_2" ref="panel_2">
          <ContenuSecondPanelItem :activeTab="activeTab" :tabs="tabs" />
        </v-window-item>
        <v-window-item :value="2" class="panel_3" ref="panel_3">
          <ContenuThirdPanelItem :activeTab="activeTab" :tabs="tabs" />
        </v-window-item>
      </v-window>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800px"
      min-width="400px"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            clearable
            variant="underlined"
            v-model="currentTheme.TXT_Theme"
            label="Thème"
          ></v-text-field>
          <v-text-field
            clearable
            variant="underlined"
            v-model="currentTheme.TXT_Description"
            label="Description"
          ></v-text-field>
          <v-text-field
            clearable
            variant="underlined"
            v-model="currentTheme.TXT_LearningObjectif"
            label="Objectif pédagogique"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink darken-1" text @click="dialog = false"
            >Fermer</v-btn
          >
          <v-btn color="pink darken-1" text @click="updateTheme"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FooterBar></FooterBar>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import ContenuFirstPanelItem from "@/components/ContenuFirstPanelItem.vue";
import ContenuSecondPanelItem from "@/components/ContenuSecondPanelItem.vue";
import ContenuThirdPanelItem from "@/components/ContenuThirdPanelItem.vue";

import NotificationBell from "@/components/NotificationBell.vue";
import axios from "axios";
import { base_url, alert_urls, header } from "@/API/config.js";
import FooterBar from "@/components/Footer.vue";

export default {
  mixins: [authExpirationMixin],
  name: "ContenuMain",
  components: {
    FooterBar,
    NavBar,
    ContenuFirstPanelItem,
    ContenuSecondPanelItem,
    ContenuThirdPanelItem,
    NotificationBell,
  },
  data() {
    return {
      activeTab: 0,
      alerts: [],
      tabs: [
        {
          label: "Cours",
        },
        {
          label: "Tests / Drills",
        },
        {
          label: "Drills Orphelins",
        },
      ],
      dialog: false,
      dialogTitle: "",
      currentTheme: {},
      snackbar: false,
      snackbarMessage: "",
    };
  },

  created() {
    this.fetchAlerts(); // Appel des alertes
  },

  methods: {
    async fetchAlerts() {
      try {
        const url = `${base_url}${alert_urls.get_all}?page=1&page_size=100000`;
        const response = await axios.get(url, header);
        this.alerts = response.data;
      } catch (error) {
        console.error("There was an error fetching the alerts:", error);
      }
    },
    setActiveTab(alertId) {
      if (alertId === 1) {
        this.activeTab = 0;
      } else if (alertId === 2) {
        this.activeTab = 2;
      }
    },
  },
};
</script>

<style lang="scss">
.contenu {
  .data-table {
    .v-table {
      .warning_theme_subthemes {
        color: #fb8c00;
        &_linked {
          color: #4caf50;
        }
        &_error {
          color: #f44336;
        }
      }
      .linked-relation {
        background: #34353cbf;
        height: 100%;
        width: 100%;
        margin-top: -2px;
        padding: 2px 8px;
        border-radius: 5px;
      }
    }
  }
  .panel_1 {
    .data-table {
      .v-table {
        .v-data-table__td:nth-child(1),
        .v-data-table__td:nth-child(5),
        .v-data-table__td:nth-child(6) {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          & .v-data-table-header__content {
            & .v-data-table-header__sort-icon {
              display: none;
            }
          }
        }
        .v-data-table__td:nth-child(5) {
          width: 60px;
        }
      }
    }
  }
  .panel_2 {
    .data-table {
      .v-table {
        .v-data-table__td:nth-child(7),
        .v-data-table__td:nth-child(8) {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          & .v-data-table-header__content {
            & .v-data-table-header__sort-icon {
              display: none;
            }
          }
        }
      }
    }
  }
  .panel_3 {
    .data-table {
      .v-table {
        .v-data-table__td:nth-child(4),
        .v-data-table__td:nth-child(5) {
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          & .v-data-table-header__content {
            & .v-data-table-header__sort-icon {
              display: none;
            }
          }
        }
        .v-data-table__td:nth-child(4) {
          width: 60px;
        }
      }
    }
  }
}
.data-table {
  .v-table {
    .v-data-table__td {
      width: 375px;
    }
  }
}

.tabs-drill {
  font-size: 12px;
  max-width: 1000px;
  margin: auto;
}
</style>
