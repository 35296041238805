<template>
  <div class="details trainee">
    <NavBar></NavBar>
    <div class="title-block">
      <button class="return" onclick="history.back()">
        <v-icon>mdi-keyboard-return</v-icon>
      </button>
      <h1 class="text-h2 my-8 text-center">Détails de l'Apprenant·e</h1>
    </div>
    <div class="mx-16 pb-10 pl-16">
      <v-card class="data-table">
        <v-card-title
          class="title-details rounded-lg text-center d-flex justify-center"
        >
          Informations
        </v-card-title>
        <div class="d-flex flex-row align-start background-details flex-wrap">
          <v-list class="details-content d-flex flex-wrap pt-0 pb-8 w-100">
            <div class="w-50_fix">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder1"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div>
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      {{ trainee[key] }}
                    </div>
                  </div>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>
            </div>

            <div class="w-50">
              <v-list-item
                class="w-100 pa-0"
                v-for="key in keyOrder2"
                :key="key"
              >
                <div class="details-table-infos px-10 py-3">
                  <div class="details-table-infos__cell">
                    <div :class="{ 'text-primary': key === 'managements' }">
                      <strong>{{ keyTextMap[key] }} :</strong>
                    </div>
                    <div class="details-table-infos-content">
                      <div v-if="key === 'managements'">
                        <div
                          v-for="manager in trainee.RelatedManagers"
                          :key="manager.ID_D_Management"
                        >
                          {{ manager.TXT_Email }}
                        </div>
                      </div>
                      <template v-else>
                        {{ trainee[key] }}
                      </template>
                    </div>
                  </div>

                  <div
                    v-if="key !== 'managements'"
                    class="details-table-infos__edit"
                    @click="openUpdateDialog(key)"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>
              </v-list-item>

              <div class="businessunits v-list-item__content px-10 py-3">
                <div class="d-flex justify-space-between align-center">
                  <h4 class="mb-2">
                    <strong>BusinessUnits :</strong>
                  </h4>
                  <div
                    class="details-table-infos__edit"
                    @click="openUpdateDialog('BusinessUnit')"
                  >
                    <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                  </div>
                </div>

                <li class="businessunits" v-for="key in keyOrder3" :key="key">
                  {{ trainee[key] }}
                </li>
              </div>
            </div>

            <div class="w-100">
              <h2 class="px-10 mt-6 text-h6 font-weight-bold">
                Commentaires :
                <v-btn icon class="ml-2" @click="openAddCommentDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </h2>

              <div class="comments-list px-10 mt-4 d-flex">
                <v-card
                  v-for="comment in reversedComments"
                  :key="comment.ID_FS_CommentsTrainee"
                  class="comment-block pa-2 mb-4 rounded-lg"
                  color="secondary"
                >
                  <div class="comment-header d-flex justify-between">
                    <h3 class="comment-date mb-2 d-flex">
                      <span class="comment-date__user">
                        {{ comment.ID_NDAppUser }}
                      </span>
                      &nbsp;-&nbsp;
                      <span class="comment-date__date">
                        {{ formatDate(comment.DAT_Created) }}
                      </span>
                    </h3>
                    <div class="comment-actions">
                      <div
                        class="comment-edit"
                        @click="openCommentUpdateDialog(comment)"
                      >
                        <v-icon icon="mdi-pencil" size="x-large"></v-icon>
                      </div>
                      <div
                        class="comment-delete"
                        @click="openDeleteDialog(comment)"
                      >
                        <v-icon icon="mdi-delete" size="x-large"></v-icon>
                      </div>
                    </div>
                  </div>
                  <p class="comment-text">{{ comment.TXT_Comment }}</p>
                </v-card>
              </div>
            </div>
          </v-list>
        </div>
        <TraineeTBSTable />
      </v-card>
    </div>

    <v-dialog
      v-if="fieldToUpdate === 'BusinessUnit'"
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      min-width="800"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="search"
            clearable
            prepend-inner-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            hide-details
            @keyup.enter="getBusinessUnits(true)"
            @click:prepend-inner="getBusinessUnits(true)"
            @click:clear="resetSearch"
            density="compact"
            bg-color="grey-darken-3"
            color="primary"
          ></v-text-field>
          <v-data-table-server
            :show-current-page="true"
            :headers="buHeaders"
            v-model:page="currentPage"
            :items="BusinessUnits"
            :items-length="totalBusinessUnits"
            items-per-page-text="Nombre d'élements par page."
            :loading="isLoading"
            no-data-text="Aucune donnée"
            @update:options="updateOptions"
            item-key="ID_D_BusinessUnit"
            density="compact"
          >
            <template v-slot:[`item.selected`]="{ item }">
              <v-checkbox
                class="d-flex justify-center ma-0 pa-0"
                :value="item.ID_D_BusinessUnit"
                v-model="selectedBusinessUnitId"
                @change="updateSelectedBusinessUnit(item)"
                density="compact"
              ></v-checkbox>
            </template>
          </v-data-table-server>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="fieldToUpdate !== 'BusinessUnit'"
      v-model="dialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newFieldValue"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Annuler</v-btn>
          <v-btn color="primary" text @click="confirmUpdate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addCommentDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Ajouter un commentaire</v-card-title>
        <v-card-text>
          <v-textarea
            rows="1"
            auto-grow
            variant="underlined"
            v-model="newComment"
            :rules="[rules.required]"
            color="primary"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addCommentDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="addComment">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      transition="dialog-top-transition"
      persistent
      max-width="800"
      min-width="400"
    >
      <v-card class="rounded-lg pa-4" color="dialog">
        <v-card-title class="headline">Supprimer le commentaire</v-card-title>
        <v-card-text
          >Voulez-vous vraiment supprimer ce commentaire ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false"
            >Annuler</v-btn
          >
          <v-btn color="primary" text @click="deleteComment">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import TraineeTBSTable from "@/components/TraineeTBSTable.vue";
import FooterBar from "@/components/Footer.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import {
  base_url,
  trainees_urls,
  bu_urls,
  comments_urls,
  user_urls,
  header,
} from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "TraineeSlug",
  components: {
    NavBar,
    TraineeTBSTable,
    FooterBar,
  },
  data() {
    return {
      trainee: {
        comments: [],
      },
      keyTextMap: {
        TXT_LastName: "Nom",
        TXT_FirstName: "Prénom",
        TXT_Email: "Email",
        TXT_BirthState: "Birthstate",
        DAT_BirthDate: "Date de naissance",
        TXT_PhoneNumber: "Numéro de tel",
        TXT_JobTitle: "Jobtitle",
        ID_D_BusinessUnit: "Business Unit",
        managements: "Management",
      },
      keyOrder1: [
        "TXT_LastName",
        "TXT_FirstName",
        "TXT_Email",
        "TXT_BirthState",
        "DAT_BirthDate",
        "TXT_PhoneNumber",
      ],
      keyOrder2: ["TXT_JobTitle", "managements"],
      keyOrder3: [
        "TXT_BusinessUnitLevel1",
        "TXT_BusinessUnitLevel2",
        "TXT_BusinessUnitLevel3",
        "TXT_BusinessUnitLevel4",
        "TXT_BusinessUnitLevel5",
      ],
      dialog: false,
      dialogTitle: "",
      newFieldValue: "",
      fieldToUpdate: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",
      currentCommentID: null,
      addCommentDialog: false,
      newComment: "",
      deleteDialog: false,
      commentToDelete: null,
      Managements: [],
      BusinessUnits: [],
      isLoading: false,
      search: "",
      selectedBusinessUnitId: null,
      initialBusinessUnitId: null,
      totalBusinessUnits: 0,
      currentPage: 1,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      buHeaders: [
        {
          title: "Sélectionner",
          value: "selected",
          key: "selected",
          sortable: false,
          width: "100px",
        },
        {
          title: "BU Niveau 1",
          value: "TXT_BusinessUnitLevel1",
          key: "TXT_BusinessUnitLevel1",
        },
        {
          title: "BU Niveau 2",
          value: "TXT_BusinessUnitLevel2",
          key: "TXT_BusinessUnitLevel2",
        },
        {
          title: "BU Niveau 3",
          value: "TXT_BusinessUnitLevel3",
          key: "TXT_BusinessUnitLevel3",
        },
        {
          title: "BU Niveau 4",
          value: "TXT_BusinessUnitLevel4",
          key: "TXT_BusinessUnitLevel4",
        },
        {
          title: "BU Niveau 5",
          value: "TXT_BusinessUnitLevel5",
          key: "TXT_BusinessUnitLevel5",
        },
      ],
      rules: {
        required: (value) => !!value || "Ce champ est requis.",
      },
    };
  },

  methods: {
    getUserDetails(id) {
      const url = `${base_url}${user_urls.get_details(id)}`;
      return axios.get(url, header);
    },

    getTraineeDetails() {
      const traineeId = this.$route.params.id;
      const url = `${base_url}${trainees_urls.get_details(traineeId)}`;
      const commentsUrl = `${base_url}${comments_urls.trainee_all}`;

      axios
        .get(url, header)
        .then((response) => {
          this.trainee = {
            ...response.data,
          };
          if (this.trainee.ID_D_BusinessUnit) {
            this.selectedBusinessUnitId = this.trainee.ID_D_BusinessUnit;
            this.initialBusinessUnitId = this.trainee.ID_D_BusinessUnit;
          }
          return this.getUserDetails(this.$store.state.auth.id);
        })
        .then((userResponse) => {
          this.trainee.TXT_LastEditedBy = userResponse.data.username;
          return axios.get(commentsUrl, header);
        })
        .then((commentsResponse) => {
          this.trainee.comments = commentsResponse.data.filter(
            (comment) => comment.ID_D_Trainee === parseInt(traineeId)
          );
          const uniqueUserIds = [
            ...new Set(
              this.trainee.comments.map((comment) => comment.ID_NDAppUser)
            ),
          ];
          return Promise.all(
            uniqueUserIds.map((userId) => this.getUserDetails(userId))
          );
        })
        .then((userResponses) => {
          userResponses.forEach((userResponse) => {
            const username = userResponse.data.username;
            this.trainee.comments.forEach((comment) => {
              if (comment.ID_NDAppUser === userResponse.data.id) {
                comment.ID_NDAppUser = username;
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching trainee details:", error);
        });
    },

    async getBusinessUnits(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.isLoading = true;
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${bu_urls.get_all}?page=${page}&page_size=${itemsPerPage}`;
      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }
      try {
        const response = await axios.get(url, header);
        this.totalBusinessUnits = response.data.count;
        this.BusinessUnits = response.data.results.results.map((bu) => ({
          ...bu,
          selected: bu.ID_D_BusinessUnit === this.selectedBusinessUnitId,
        }));
        this.currentPage = page;
      } catch (error) {
        console.error("Error fetching Business units:", error);
      } finally {
        this.isLoading = false;
      }
    },

    openUpdateDialog(key) {
      this.fieldToUpdate = key;
      if (key === "BusinessUnit") {
        this.selectedBusinessUnitId = this.initialBusinessUnitId;
        this.getBusinessUnits();
        this.dialogTitle = "Modifier la Business Unit";
      } else {
        this.dialogTitle = `Modifier ${this.keyTextMap[key]}`;
        this.newFieldValue = this.trainee[key];
      }
      this.dialog = true;
    },

    confirmUpdate() {
      if (this.fieldToUpdate === "BusinessUnit") {
        this.updateTrainee("ID_D_BusinessUnit", this.selectedBusinessUnitId);
      } else if (this.fieldToUpdate === "TXT_Comment") {
        this.updateComment(this.currentCommentID, this.newFieldValue);
      } else if (this.newFieldValue) {
        this.updateTrainee(this.fieldToUpdate, this.newFieldValue);
      }
      this.dialog = false;
    },

    updateComment(commentId, newComment) {
      const url = `${base_url}${comments_urls.trainee_details(commentId)}`;

      axios
        .put(url, { TXT_Comment: newComment }, header)
        .then(() => {
          const commentIndex = this.trainee.comments.findIndex(
            (comment) => comment.ID_FS_CommentsTrainee === commentId
          );
          this.trainee.comments[commentIndex].TXT_Comment = newComment;

          this.snackbarMessage = `Le commentaire a été mis à jour.`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error("Error updating comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du <strong>Commentaire</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    updateTrainee(field, newValue) {
      const traineeId = this.$route.params.id;
      const url = `${base_url}${trainees_urls.update(traineeId)}`;

      const updatedData = {
        ...this.trainee,
        [field]: newValue,
      };

      if (field === "ID_D_BusinessUnit" && newValue === null) {
        updatedData[field] = this.initialBusinessUnitId;
      }

      axios
        .put(url, updatedData, header)
        .then((response) => {
          this.trainee = response.data;
          if (field === "ID_D_BusinessUnit") {
            this.initialBusinessUnitId = this.trainee.ID_D_BusinessUnit;
            this.selectedBusinessUnitId = this.initialBusinessUnitId;
          }

          this.getTraineeDetails();

          this.snackbarMessage = `Le champ <strong>${this.keyTextMap[field]}</strong> a été mis à jour`;
          this.snackbarColor = "success";
          this.snackbar = true;
        })
        .catch((error) => {
          console.error(error);

          this.snackbarMessage = `Une erreur s'est produite lors de la mise à jour du champ <strong>${this.keyTextMap[field]}</strong>`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },

    openCommentUpdateDialog(comment) {
      this.dialogTitle = `Modifier le commentaire de ${this.formatDate(
        comment.DAT_Created
      )}`;
      this.fieldToUpdate = "TXT_Comment";
      this.newFieldValue = comment.TXT_Comment;
      this.dialog = true;
      this.currentCommentID = comment.ID_FS_CommentsTrainee;
    },

    openAddCommentDialog() {
      this.newComment = "";
      this.addCommentDialog = true;
    },

    addComment() {
      const url = `${base_url}${comments_urls.trainee_all}`;

      const commentData = {
        ID_NDAppUser: this.$store.state.auth.id,
        DAT_Created: new Date().toISOString(),
        TXT_Comment: this.newComment,
        ID_D_Trainee: this.$route.params.id,
      };

      axios
        .post(url, commentData, header)
        .then(() => {
          this.getTraineeDetails();

          this.snackbarMessage = `Votre commentaire a été ajouté avec succès.`;
          this.snackbarColor = "success";
          this.snackbar = true;

          this.addCommentDialog = false;
        })
        .catch((error) => {
          console.error("Error adding comment:", error);

          this.snackbarMessage = `Une erreur s'est produite lors de l'ajout du commentaire`;
          this.snackbarColor = "error";
          this.snackbar = true;
        });
    },

    openDeleteDialog(comment) {
      this.commentToDelete = comment;
      this.deleteDialog = true;
    },

    deleteComment() {
      if (this.commentToDelete) {
        const url = `${base_url}${comments_urls.delete_trainee(
          this.commentToDelete.ID_FS_CommentsTrainee
        )}`;

        axios
          .delete(url, header)
          .then(() => {
            this.getTraineeDetails();

            this.snackbarMessage = `Votre commentaire a été supprimé.`;
            this.snackbarColor = "success";
            this.snackbar = true;

            this.deleteDialog = false;
          })
          .catch((error) => {
            console.error("Error deleting comment:", error);

            this.snackbarMessage = `Erreur lors de la suppression du commentaire`;
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      }
    },

    formatBusinessUnitTitle(levels) {
      const title = levels
        .filter(Boolean)
        .map((item, index) => `${index + 1}.${item}`)
        .join(" - ");
      return title.length > 75 ? title.substring(0, 75) + "..." : title;
    },

    updateSelectedBusinessUnit(item) {
      this.selectedBusinessUnitId = item.ID_D_BusinessUnit;
      this.BusinessUnits.forEach((bu) => {
        bu.selected = bu.ID_D_BusinessUnit === this.selectedBusinessUnitId;
      });
    },

    updateOptions(newOptions) {
      this.options = newOptions;
      if (this.fieldToUpdate === "BusinessUnit") {
        this.getBusinessUnits();
      }
    },

    resetSearch() {
      this.search = "";
      if (this.fieldToUpdate === "BusinessUnit") {
        this.getBusinessUnits(true);
      }
    },
  },

  computed: {
    reversedComments() {
      return [...(this.trainee.comments || [])].reverse();
    },
  },

  created() {
    this.getTraineeDetails();
  },
  mounted() {
    document.title = "Apprenant·e - Détails";
  },
};
</script>

<style lang="scss">
.details.trainee .data-table .v-table .v-data-table__td {
  width: 100%;
  &:nth-child(1) {
    width: 33%;
  }
}

.w-50_fix {
  width: calc(50% - 1px);
  margin-right: 1px;
}
</style>
