<template>
  <div>
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Business Units</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getBusinessUnits(true)"
            @click:append="getBusinessUnits(true)"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>

        <v-data-table-server
          v-model:page="currentPage"
          :show-current-page="true"
          :headers="headers"
          items-per-page-text="Nombre d'élements par page."
          :items-per-page-options="[10, 20, 50, 100]"
          :page="currentPage"
          :items-length="totalBusinessUnits"
          :loading="loading"
          @update:options="options = $event"
          :items="businessUnits"
        ></v-data-table-server>
      </v-card>
    </div>
    <FooterBar></FooterBar>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, bu_urls, header } from "@/API/config.js";
import FooterBar from "@/components/Footer.vue";

export default {
  mixins: [authExpirationMixin],
  name: "BusinessUnits",
  components: {
    FooterBar,
    NavBar,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      search: "",
      isSearchActive: false,

      totalBusinessUnits: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      sortBy: [],
      businessUnits: [],
      selectedColumn: null,

      allDataLoaded: false,

      headers: [
        {
          title: "BU Niveau 1",
          value: "TXT_BusinessUnitLevel1",
          key: "TXT_BusinessUnitLevel1",
          sortable: false,
        },
        {
          title: "BU Niveau 2",
          value: "TXT_BusinessUnitLevel2",
          key: "TXT_BusinessUnitLevel2",
          sortable: false,
        },
        {
          title: "BU Niveau 3",
          value: "TXT_BusinessUnitLevel3",
          key: "TXT_BusinessUnitLevel3",
          sortable: false,
        },
        {
          title: "BU Niveau 4",
          value: "TXT_BusinessUnitLevel4",
          key: "TXT_BusinessUnitLevel4",
          sortable: false,
        },
        {
          title: "BU Niveau 5",
          value: "TXT_BusinessUnitLevel5",
          key: "TXT_BusinessUnitLevel5",
          sortable: false,
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.getBusinessUnits();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      const cacheVersionKeys = Object.keys(localStorage).filter((key) =>
        key.startsWith("cacheVersion")
      );

      if (cacheVersionKeys.length > 0) {
        const latestCacheVersion = cacheVersionKeys.reduce((latest, key) => {
          const version = parseInt(localStorage.getItem(key));
          return version > latest ? version : latest;
        }, 0);

        return latestCacheVersion.toString();
      } else {
        const response = await axios.get(
          `${base_url}${bu_urls.get_all}`,
          header
        );
        const cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
        return cacheVersion;
      }
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem(`cacheVersion_${newCacheVersion}`, newCacheVersion);
    },

    async getBusinessUnits(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;

      let url = `${base_url}${bu_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.businessUnits = response.data.results.results;
        this.totalBusinessUnits = response.data.count;

        if (response.data.results.cache_version !== cacheVersion) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.refreshData();
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      } finally {
        this.loading = false;
      }
    },

    async refreshData() {
      const newCacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${bu_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${newCacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.businessUnits = response.data.results.results;
        this.totalBusinessUnits = response.data.count;
      } catch (error) {
        console.error("Error refreshing business units data:", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getBusinessUnits(true);
    },
  },

  mounted() {
    document.title = "Business Units";
    this.getBusinessUnits();
  },
};
</script>

<style lang="scss"></style>
