<template>
  <div class="managements">
    <NavBar></NavBar>
    <h1 class="text-h2 my-8 text-center">Managements</h1>
    <div class="mx-16 pb-10">
      <v-card class="data-table">
        <v-card-title>
          <v-text-field
            class="min-100"
            v-model="search"
            clearable
            append-icon="mdi-magnify"
            label="Rechercher..."
            single-line
            color="primary"
            @keyup.enter="getManagements(true)"
            @click:append="getManagements(true)"
            @click:clear="resetSearch"
          ></v-text-field>
        </v-card-title>
        <v-data-table-server
          v-model:page="currentPage"
          :headers="headers"
          :show-current-page="true"
          :items="managements"
          items-per-page-text="Nombre d'élements par page."
          :page="currentPage"
          :items-length="totalManagements"
          :loading="loading"
          @update:options="options = $event"
          :items-per-page-options="[10, 20, 50, 100]"
          no-data-text="Aucune donnée"
        >
          <template v-slot:[`item.BIT_ReportReceiver`]="{ item }">
            <v-checkbox
              class="d-flex justify-center"
              v-model="item.BIT_ReportReceiver"
              disabled
            ></v-checkbox>
          </template>
          <template v-slot:[`item.BIT_IsTrainingDepartment`]="{ item }">
            <v-checkbox
              class="d-flex justify-center"
              v-model="item.BIT_IsTrainingDepartment"
              disabled
            ></v-checkbox>
          </template>
          <template v-slot:[`item.BIT_ReportReceiverChildBU`]="{ item }">
            <v-checkbox
              class="d-flex justify-center"
              v-model="item.BIT_ReportReceiverChildBU"
              disabled
            ></v-checkbox>
          </template>
          <template v-slot:[getSlotKey(`TXT_BusinessUnitLevel1`)]="{ item }">
            {{ item.bu.TXT_BusinessUnitLevel1 }}
          </template>
          <template v-slot:[getSlotKey(`TXT_BusinessUnitLevel2`)]="{ item }">
            {{ item.bu.TXT_BusinessUnitLevel2 }}
          </template>
          <template v-slot:[getSlotKey(`TXT_BusinessUnitLevel3`)]="{ item }">
            {{ item.bu.TXT_BusinessUnitLevel3 }}
          </template>
          <template v-slot:[getSlotKey(`TXT_BusinessUnitLevel4`)]="{ item }">
            {{ item.bu.TXT_BusinessUnitLevel4 }}
          </template>
          <template v-slot:[getSlotKey(`TXT_BusinessUnitLevel5`)]="{ item }">
            {{ item.bu.TXT_BusinessUnitLevel5 }}
          </template>

          <template v-slot:[`item.TXT_Email`]="{ item }">
            <router-link :to="`/managements/${item.ID_D_Management}`">{{
              item.TXT_Email
            }}</router-link>
          </template>
        </v-data-table-server>
      </v-card>
    </div>

    <v-snackbar v-model="snackbar" color="success" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
    <FooterBar></FooterBar>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, managements_urls, header } from "@/API/config.js";
import FooterBar from "@/components/Footer.vue";

export default {
  mixins: [authExpirationMixin],
  name: "ManagementsMain",
  components: {
    FooterBar,
    NavBar,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      itemsPerPage: 10,
      totalManagements: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      search: "",
      sortBy: [],
      excludedColumns: [
        "BIT_ReportReceiver",
        "BIT_IsTrainingDepartment",
        "BIT_ReportReceiverChildBU",
      ],

      managements: [],
      currentManagement: null,
      snackbar: false,
      snackbarMessage: "",
      headers: [
        {
          title: "E-Mail",
          value: "TXT_Email",
          key: "TXT_Email",
          sortable: false,
        },
        {
          title: "Réception reporting",
          value: "BIT_ReportReceiver",
          key: "BIT_ReportReceiver",
          sortable: false,
        },
        {
          title: "Service de formation",
          value: "BIT_IsTrainingDepartment",
          key: "BIT_IsTrainingDepartment",
          sortable: false,
        },
        {
          title: "Visibilité BU enfants",
          value: "BIT_ReportReceiverChildBU",
          key: "BIT_ReportReceiverChildBU",
          sortable: false,
        },
        {
          title: "BU Niveau 1",
          value: "bu.TXT_BusinessUnitLevel1",
          key: "bu.TXT_BusinessUnitLevel1",
          sortable: false,
        },
        {
          title: "BU Niveau 2",
          value: "bu.TXT_BusinessUnitLevel2",
          key: "bu.TXT_BusinessUnitLevel2",
          sortable: false,
        },
        {
          title: "BU Niveau 3",
          value: "bu.TXT_BusinessUnitLevel3",
          key: "bu.TXT_BusinessUnitLevel3",
          sortable: false,
        },
        {
          title: "BU Niveau 4",
          value: "bu.TXT_BusinessUnitLevel4",
          key: "bu.TXT_BusinessUnitLevel4",
          sortable: false,
        },
        {
          title: "BU Niveau 5",
          value: "bu.TXT_BusinessUnitLevel5",
          key: "bu.TXT_BusinessUnitLevel5",
          sortable: false,
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.getManagements();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      const cacheVersionKeys = Object.keys(localStorage).filter((key) =>
        key.startsWith("cacheVersion")
      );

      if (cacheVersionKeys.length > 0) {
        const latestCacheVersion = cacheVersionKeys.reduce((latest, key) => {
          const version = parseInt(localStorage.getItem(key));
          return version > latest ? version : latest;
        }, 0);

        return latestCacheVersion.toString();
      } else {
        const response = await axios.get(
          `${base_url}${managements_urls.get_all}`,
          header
        );
        const cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
        return cacheVersion;
      }
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem(`cacheVersion_${newCacheVersion}`, newCacheVersion);
    },

    async getManagements(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let managementsUrl = `${base_url}${managements_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        managementsUrl += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        let managementsResponse = await axios.get(managementsUrl, header);
        let managementsData = managementsResponse.data.results;
        this.totalManagements = managementsResponse.data.count;

        this.managements = managementsData.results.map((management) => ({
          ...management,
          BusinessUnit: management.bu || {},
          DAT_LastEdited: management.DAT_LastEdited
            ? this.formatDate(management.DAT_LastEdited)
            : "N/A",
          DAT_Created: management.DAT_Created,
          DAT_Created_Display: management.DAT_Created
            ? this.formatDate(management.DAT_Created)
            : "N/A",
        }));

        if (managementsResponse.data.results.cache_version !== cacheVersion) {
          this.updateCacheBusting(
            managementsResponse.data.results.cache_version
          );
          await this.refreshData();
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        this.loading = false;
      }
    },

    async refreshData() {
      const newCacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;
      let managementsUrl = `${base_url}${managements_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${newCacheVersion}`;

      if (this.search) {
        managementsUrl += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        let managementsResponse = await axios.get(managementsUrl, header);
        let managementsData = managementsResponse.data.results;
        this.totalManagements = managementsResponse.data.count;

        this.managements = managementsData.results.map((management) => ({
          ...management,
          BusinessUnit: management.bu || {},
          DAT_LastEdited: management.DAT_LastEdited
            ? this.formatDate(management.DAT_LastEdited)
            : "N/A",
          DAT_Created: management.DAT_Created,
          DAT_Created_Display: management.DAT_Created
            ? this.formatDate(management.DAT_Created)
            : "N/A",
        }));
      } catch (error) {
        console.error("Erreur lors du rafraîchissement des données :", error);
      }
    },

    resetSearch() {
      this.search = "";
      this.getManagements(true);
    },

    getSlotKey(item, level) {
      return item && item.BusinessUnit ? `item.BusinessUnit.${level}` : "";
    },
    getBusinessUnitValue(item, level) {
      return item.BusinessUnit && item.BusinessUnit[level]
        ? item.BusinessUnit[level]
        : "";
    },

    formatDate(date) {
      const dateObject = new Date(date);
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      const formattedDate = dateObject.toLocaleDateString("fr-FR", options);
      return formattedDate;
    },
  },

  mounted() {
    document.title = "Managements";
    this.getManagements();
  },
};
</script>

<style lang="scss">
.managements
  .data-table
  .v-table
  .v-data-table__td:nth-child(n + 2):nth-child(-n + 4) {
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  flex-wrap: wrap;
  .v-input__control {
    margin-top: -3px;
  }
}

.managements .data-table .v-table {
  .v-data-table__th:nth-child(n + 2):nth-child(-n + 4) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .v-data-table-header__content {
      margin-left: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        text-align: center;
      }
    }
  }
}
</style>
