<template>
  <v-card class="data-table">
    <v-card-title>
      <v-text-field
        class="min-100"
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        label="Rechercher..."
        single-line
        color="primary"
        @keyup.enter="getTestDrills(true)"
        @click:append="getTestDrills(true)"
        @click:clear="resetSearch"
      ></v-text-field>
    </v-card-title>
    <v-data-table-server
      v-model:page="currentPage"
      :show-current-page="true"
      :items-per-page-options="[10, 20, 50, 100]"
      items-per-page-text="Nombre d'élements par page."
      :headers="headers"
      :page="currentPage"
      :items-length="totalTestDrills"
      :items="testdrills"
      no-data-text="Aucune donnée"
      :loading="loading"
      loading-text="Loading... Please wait"
      @update:options="options = $event"
    >
      <template v-slot:[`item.Selected_Course`]="{ item }">
        <div class="linked-relation">
          {{
            item.RelatedCourses &&
            item.RelatedCourses.find((course) => course.BIT_ActiveLink === "1")
              ? item.RelatedCourses.find(
                  (course) => course.BIT_ActiveLink === "1"
                ).TXT_L3_LMSTitle
              : ""
          }}
        </div>
      </template>

      <template v-slot:[`item.TXT_Liaison`]="{ item }">
        <template v-if="item.RelatedCourses">
          <p>
            {{ getActiveRelatedCourse(item.RelatedCourses).TXT_Theme }}
          </p>
        </template>
      </template>

      <template v-slot:[`item.TXT_Liaison_2`]="{ item }">
        <template v-if="item.RelatedCourses">
          <p>
            {{ getActiveRelatedCourse(item.RelatedCourses).TXT_SubTheme }}
          </p>
        </template>
      </template>

      <template v-slot:[`item.Édition`]="{ item }">
        <v-icon
          icon="mdi-pencil"
          size="x-large"
          @click="openDialog(item)"
        ></v-icon>
      </template>

      <template v-slot:[`item.alert`]="{ item }">
        <div v-if="warning_theme_subthemes_error(item)">
          <v-icon class="warning_theme_subthemes_error">mdi-bell-alert</v-icon>
          <v-tooltip activator="parent" location="left">
            Merci de sélectioner un Cours
          </v-tooltip>
        </div>
        <div v-else-if="warning_theme_subthemes(item)">
          <v-icon class="warning_theme_subthemes">mdi-comment-alert</v-icon>
          <v-tooltip activator="parent" location="left">
            Aucun Thème / Sous-Thème lié
          </v-tooltip>
        </div>
        <div v-else-if="warning_theme_subthemes_linked(item)">
          <v-icon class="warning_theme_subthemes_linked"
            >mdi-comment-check</v-icon
          >
        </div>
      </template>
    </v-data-table-server>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Sélectionnez un cours</v-card-title>
        <v-card-text>
          <v-select
            :items="coursesForDialog"
            item-text="title"
            item-value="value"
            label="Cours"
            v-model="selectedCourse"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
          <v-btn color="primary" text @click="saveCourse">Sauvegarder</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="5000">
      <p v-html="snackbarMessage"></p>
    </v-snackbar>
  </v-card>
</template>

<script>
import authExpirationMixin from "@/mixins/isLogged.js";
import axios from "axios";
import { base_url, testdrills_urls, header } from "@/API/config.js";

export default {
  mixins: [authExpirationMixin],
  name: "ContenuThirdPanelItem",
  components: {},
  props: {},
  data() {
    return {
      search: "",
      sortBy: [],
      themes: [],
      subthemes: [],
      testdrills: [],
      totalTestDrills: 0,
      loading: false,
      itemsPerPage: 10,
      currentPage: 1,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      cacheVersion: null,
      snackbar: false,
      snackbarMessage: "",
      snackbarColor: "success",

      dialog: false,
      coursesForDialog: [],
      selectedCourse: null,

      dialogJustOpened: false,
      testdrillId: null,

      isLoading: "",

      selectedColumn: null,
      selectedThemes: {},
      selectedSubThemes: [],
      value: null,
      headers: [
        {
          title: "Test",
          value: "TXT_LMSTitle",
          key: "TXT_LMSTitle",
          sortable: false,
        },
        {
          title: "Parcours",
          value: "TXT_TrainingBlocName",
          key: "TXT_TrainingBlocName",
          sortable: false,
        },
        {
          title: "Drill",
          value: "TXT_L3_LMSTitle",
          key: "TXT_L3_LMSTitle",
          sortable: false,
        },
        {
          title: "Cours",
          value: "Selected_Course",
          key: "Selected_Course",
          sortable: false,
        },
        {
          title: "Liaison Thème",
          value: "TXT_Liaison",
          key: "TXT_Liaison",
          sortable: false,
        },
        {
          title: "Liaison Sous-Thème",
          value: "TXT_Liaison_2",
          key: "TXT_Liaison_2",
          sortable: false,
        },
        {
          title: "Édition",
          value: "Édition",
          key: "Édition",
          sortable: false,
        },
        {
          title: "Attention",
          value: "alert",
          key: "alert",
          sortable: false,
        },
      ],
    };
  },

  computed: {},

  watch: {
    options: {
      handler() {
        this.getTestDrills();
      },
      deep: true,
    },
  },

  methods: {
    async initializeCacheBusting() {
      let cacheVersion = localStorage.getItem("cacheVersion");
      if (!cacheVersion) {
        const response = await axios.get(
          `${base_url}${testdrills_urls.get_all}`,
          header
        );
        cacheVersion = response.data.results.cache_version;
        localStorage.setItem("cacheVersion", cacheVersion);
      }
      return cacheVersion;
    },

    updateCacheBusting(newCacheVersion) {
      localStorage.setItem("cacheVersion", newCacheVersion);
    },

    async getTestDrills(resetPage = false) {
      if (resetPage) {
        this.currentPage = 1;
        this.options.page = 1;
      }

      this.loading = true;
      const cacheVersion = await this.initializeCacheBusting();
      const { itemsPerPage, page } = this.options;

      let url = `${base_url}${testdrills_urls.get_all}?page=${page}&page_size=${itemsPerPage}&cacheVersion=${cacheVersion}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        this.totalTestDrills = response.data.count;
        this.testdrills = response.data.results.results.map((testdrill) => ({
          ...testdrill,
        }));
        this.loading = false;
      } catch (error) {
        console.error("Error fetching drills:", error);
      }

      this.checkForUpdates();
    },

    async checkForUpdates() {
      const { itemsPerPage, page } = this.options;
      let url = `${base_url}${
        testdrills_urls.get_all
      }?page=${page}&page_size=${itemsPerPage}&timestamp=${new Date().getTime()}`;

      if (this.search) {
        url += `&query=${encodeURIComponent(this.search)}`;
      }

      try {
        const response = await axios.get(url, header);
        if (
          response.data.results.cache_version !==
          localStorage.getItem("cacheVersion")
        ) {
          this.updateCacheBusting(response.data.results.cache_version);
          await this.getTestDrills();
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification des mises à jour :",
          error
        );
      }
    },

    resetSearch() {
      this.search = "";
      this.getTrainingCourses(true);
    },

    getActiveRelatedCourse(courseArray) {
      const activeCourse = courseArray.find(
        (course) => course.BIT_ActiveLink === "1"
      );
      return activeCourse || {};
    },

    warning_theme_subthemes_error(item) {
      return !item || !item.RelatedCourses || item.RelatedCourses.length === 0;
    },
    warning_theme_subthemes(item) {
      if (item && item.RelatedCourses) {
        const activeCourse = item.RelatedCourses.find(
          (course) => course.BIT_ActiveLink === "1"
        );
        return (
          activeCourse &&
          (!activeCourse.ID_D_Theme || !activeCourse.ID_D_SubTheme)
        );
      }
      return false;
    },
    warning_theme_subthemes_linked(item) {
      if (item && item.RelatedCourses) {
        const activeCourse = item.RelatedCourses.find(
          (course) => course.BIT_ActiveLink === "1"
        );
        return (
          activeCourse && activeCourse.ID_D_Theme && activeCourse.ID_D_SubTheme
        );
      }
      return false;
    },

    openDialog(item) {
      const rawCourses = item.RelatedCourses || [];
      this.currentItem = item;

      this.coursesForDialog = rawCourses.map((course) => ({
        title: course.TXT_L3_LMSTitle,
        value: course.ID_BT_Training_TrainingItem,
      }));

      const activeCourse = rawCourses.find(
        (course) => course.BIT_ActiveLink === "1"
      );
      if (activeCourse) {
        this.selectedCourse = {
          title: activeCourse.TXT_L3_LMSTitle,
          value: activeCourse.ID_BT_Training_TrainingItem,
        };
      } else {
        this.selectedCourse = null;
      }

      this.dialog = true;
    },

    async saveCourse() {
      const trainingCourseId = this.currentItem.ID_BT_TestTrainingItem;
      const putUrl = `${base_url}${testdrills_urls.updateRelation(
        trainingCourseId
      )}`;

      const dataToUpdate = {
        ID_BT_Training_TrainingItem: this.selectedCourse,
        ID_BT_TestTrainingItem: trainingCourseId,
        ID_D_TrainingItem: this.currentItem.ID_D_TrainingItem,
        ID_D_Test: this.currentItem.ID_D_Test,
      };

      try {
        const response = await axios.put(putUrl, dataToUpdate, header);

        if (response.status === 200) {
          this.dialog = false;

          const newCacheVersion = new Date().getTime().toString();
          localStorage.setItem("cacheVersion", newCacheVersion);

          this.snackbar = true;
          this.snackbarMessage = `Une nouvelle <strong>Liaison</strong> a été ajouté avec succès`;
          this.snackbarColor = "success";
          this.getTestDrills();
        }
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);

        this.snackbarMessage = `Une erreur s'est produite lors de la liaison <strong>Thème Sous-Thème</strong>`;
        this.snackbarColor = "error";
        this.snackbar = true;
      }
    },
  },

  created() {
    this.getTestDrills();
  },
};
</script>

<style lang="scss"></style>
